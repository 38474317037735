<template>
  <div id="ourJ">
    <v-container class="oo">
    <v-container class="oj">
      <div class="title text-center pt-5">
        <h1>
          Our
          <a style="color:#B10801">Journal</a>
          <!-- {{ title }} -->
        </h1>
        <br />
      </div>
      <v-row justify="center">
        <v-col cols="12" md="3" lg="3" class="pa-10">
          <v-card class="pa-10 text-center rounded-xl"
          href="https://acityajournal.com/index.php/jebd" target="_blank">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/jebdVikrama.png"
              max-height="225"
              max-width="218"
            ></v-img>
            <br />
            <h5>{{ journal1 }}</h5>
            <br />
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="3" class="pa-10">
          <v-card class="pa-10 text-center rounded-xl"
          href="https://acityajournal.com/index.php/jpls" target="_blank">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/jplsVikrama.png"
              max-height="225"
              max-width="218"
            ></v-img>
            <br />
            <h5>{{ journal2 }}</h5>
            <br />
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="3" class="pa-10">
          <v-card class="pa-10 text-center rounded-xl"
          href="https://acityajournal.com/index.php/ljes" target="_blank">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/lokaVikrama.png"
              max-height="225"
              max-width="218"
            ></v-img>
            <br />
            <h5>{{ journal3 }}</h5>
            <br />
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="3" class="pa-10">
          <v-card class="pa-10 text-center rounded-xl"
          href="https://acityajournal.com/index.php/ijssc" target="_blank">
            <v-img
              class="mx-auto"
              style="position: relative"
              src="@/assets/ijsccVikrama.png"
              max-height="225"
              max-width="218"
            ></v-img>
            <br />
            <h5>{{ journal4 }}</h5>
            <br />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Our Journal",
      journal1: "JOURNAL OF ENTREPRENEURIAL AND BUSINESS DIVERSITY",
      journal2: "JOURNAL OF POLITICAL AND LEGAL SOVEREIGNTY",
      journal3: "LOKA: JOURNAL OF ENVIRONMENTAL SCIENCES",
      journal4: "INTEGRATION: JOURNAL OF SOCIAL SCIENCES AND CULTURE"
    };
  }
};
</script>

<style scoped>
.v-container.oo {
  background: linear-gradient(
    45deg,
    rgba(238, 239, 235, 1) 72%,
    rgba(177, 8, 1, 0.5046393557422969) 100%
  );
  max-width: 100%;
}
.v-container.oj {
  background: linear-gradient(214deg, rgba(238,239,235,1) 0%, rgba(177,8,1,0) 0%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  /* margin-left: auto;
  margin-right: auto; */
  /* padding-right: 100px;
  padding-left: 100px; */
}

.v-container.oj {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.oj {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.oj {
    max-width: 1281px;
  }
}

h1 {
  font-size: 60px;
}

h5 {
  font-size: 12px;
}

.v-card {
  width: 288px;
  height: 325px;
}

.v-card:hover{ 
     box-shadow: 1px 8px 20px #B10801;
    -webkit-transition:  box-shadow .3s ease-in;
  }

.v-img{
  border-radius: 10%;
}
</style>