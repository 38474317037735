<template>
  <div id="footer">
    <footer class="site-footer">
      <v-container class="fo">
        <hr />
        <v-row class="mb-1" no-gutters>
          <v-col cols="12" md="4" mr="10">
            <v-img
              class="pa-10"
              style="position: relative"
              src="@/assets/logoPutih.png"
              max-height="320"
              max-width="320"
            ></v-img>
            <div>
              <h4>National Journal Publication Solutions (ISSN and SINTA Accredited) and Reputable International Journals</h4>
            </div>
          </v-col>
          <v-col cols="12" md="2" pa="10" class="ftxt">
            <div class="ml-0">
              <h2>Sitemap</h2>
              <a @click="scroll('hero')">
                <h4 class="nav">
                  <v-icon>mdi-menu-right</v-icon>Home
                </h4>
              </a>
              <a
                href="https://drive.google.com/drive/u/1/folders/1o4xncwPio_dOFtl4uJGlGI4dPndfXTV4"
                target="_blank"
              >
                <h4 class="nav">
                  <v-icon>mdi-menu-right</v-icon>Discontinued Scopus
                </h4>
              </a>
              <a @click="scroll('ourJ')">
                <h4 class="nav">
                  <v-icon>mdi-menu-right</v-icon>Journal
                </h4>
              </a>
              <a @click="scroll('ourS')">
                <h4 class="nav">
                  <v-icon>mdi-menu-right</v-icon>Service
                </h4>
              </a>
              <a @click="scroll('ourE')">
                <h4 class="nav">
                  <v-icon>mdi-menu-right</v-icon>Expertise
                </h4>
              </a>
              <a @click="scroll('editS')">
                <h4 class="nav">
                  <v-icon>mdi-menu-right</v-icon>Procedures
                </h4>
              </a>
              <a @click="scroll('trans')">
                <h4 class="nav">
                  <v-icon>mdi-menu-right</v-icon>Schema
                </h4>
              </a>
            </div>
          </v-col>
          <v-col cols="12" md="3" class="ftxt">
            <div>
              <h2>Contact</h2>
              <v-row>
                <v-col cols="1" md="1">
                  <h4>
                    <v-icon>mdi-map-marker-outline</v-icon>
                  </h4>
                </v-col>
                <v-col cols="11" md="11">
                  <h4>Jl. Bedugul No.25, Sidakarya, Denpasar Selatan, Kota Denpasar, Bali 80224</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1" md="1">
                  <h4>
                    <v-icon>mdi-email-open-outline</v-icon>
                  </h4>
                </v-col>
                <v-col cols="11" md="11">
                  <h4>info@acityajournal.com</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="1" md="1">
                  <h4>
                    <v-icon>mdi-phone-outline</v-icon>
                  </h4>
                </v-col>
                <v-col cols="11" md="11">
                  <h4>Mobile: +62 8112 0023 048</h4>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12" md="3" class="ftxt">
            <div class="ml-5">
              <h2>Colaboration With</h2>
              <v-row>
                <v-col cols="5" ls="3" md="3">
                  <v-img
                    class="mb-2"
                    style="position: relative"
                    src="@/assets/campus/bumigora.png"
                    max-height="85"
                    max-width="65"
                  ></v-img>
                  <v-img
                    class="mb-2"
                    style="position: relative"
                    src="@/assets/campus/warmadewa.png"
                    max-height="65"
                    max-width="65"
                  ></v-img>
                  <v-img
                    class="mb-2"
                    style="position: relative"
                    src="@/assets/campus/unma.png"
                    max-height="65"
                    max-width="65"
                  ></v-img>
                  <v-img
                    class
                    style="position: relative"
                    src="@/assets/campus/mahendra.png"
                    max-height="65"
                    max-width="65"
                  ></v-img>
                </v-col>
                <v-col cols="5" ls="3" md="3">
                  <v-img
                    class="mb-2"
                    style="position: relative"
                    src="@/assets/campus/inata.png"
                    max-height="65"
                    max-width="65"
                  ></v-img>
                  <v-img
                    class="mb-2"
                    style="position: relative"
                    src="@/assets/campus/nyataD.png"
                    max-height="65"
                    max-width="65"
                  ></v-img>
                  <v-img
                    class="mb-2"
                    style="position: relative"
                    src="@/assets/campus/santoT.png"
                    max-height="65"
                    max-width="65"
                  ></v-img>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <br />
        <hr />
        <div class="text-center pt-5">
          <a href="https://www.strategis.co.id/">
            <h5>Copyright © 2023 PT. Keberlanjutan Strategis Indonesia</h5>
          </a>
        </div>
      </v-container>
    </footer>
  </div>
</template>
    
    <script>
export default {
  methods: {
    scroll(refName) {
      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>
  
    <style scoped>
html {
  scroll-behavior: smooth;
}

.v-container.fo {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.fo {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.fo {
    max-width: 1281px;
    /* padding-left: 170px;
    padding-right: 170px;
    padding-top: 50px; */
  }
}

a {
  text-decoration: none;
}
h5:hover {
  color: #bdbeb9;
}

h4.nav:hover {
  color: #bdbeb9;
}

h4 {
  font-family: Arial;
  font-style: Regular;
  color: #eeede9;
  pointer-events: all;
}
h5 {
  font-family: Arial;
  font-style: Regular;
  color: #eeede9;
}
h2 {
  color: #eeede9;
  padding-top: 30px;
  padding-bottom: 20px;
  font-family: Arial;
}

/* .v-col.ftxt {
  padding-left: 100px;
} */
.site-footer {
  background: #b10801;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
}
</style>
    
    