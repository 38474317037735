<template>
  <div id="ourE">
    <v-container class="aa">
      <v-container class="oe">
        <div class="title text-center">
          <h1 class="pt-5">
            Our
            <a style="color:#B10801">Expertise</a>
          </h1>
          <br />
        </div>
        <v-row justify="center">
          <v-col cols="12" md="3" lg="3" class="mt-5">
            <v-card class="pa-10 text-center rounded-xl">
              <v-img
                class="mx-auto"
                style="position: relative"
                src="@/assets/reputablePublic.png"
                max-height="125"
                max-width="125"
              ></v-img>
              <br />
              <h3>Reputable Public Exposure</h3>
              <br />
              <h4>The work and assistance of Vikrama Research Institute are conducted in a transparent and accountable manner</h4>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" lg="3" class="mt-5">
            <v-card class="pa-10 text-center rounded-xl">
              <v-img
                class="mx-auto"
                style="position: relative"
                src="@/assets/strategicPartnership.png"
                max-height="125"
                max-width="125"
              ></v-img>
              <br />
              <h3>Strategic Partnership</h3>
              <br />
              <h4>We direct a good partnership-building in national and international research collaboration.</h4>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" lg="3" class="mt-5">
            <v-card class="pa-10 text-center rounded-xl">
              <v-img
                class="mx-auto"
                style="position: relative"
                src="@/assets/qualityAndIntegration.png"
                max-height="125"
                max-width="125"
              ></v-img>
              <br />
              <h3>Quality and Integration</h3>
              <br />
              <h4>Our journals are indexed nationally and internationally and we collaborate with national and international journal publishers.</h4>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" lg="3" class="mt-5">
            <v-card class="pa-10 text-center rounded-xl">
              <v-img
                class="mx-auto"
                style="position: relative"
                src="@/assets/competencyDevelopment.png"
                max-height="125"
                max-width="125"
              ></v-img>
              <br />
              <h3>Competency Development</h3>
              <br />
              <h4>Vikrama Research Institute is managed and assisted by professionals and experienced expert team in research and scientific publications</h4>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.v-container.oe {
  max-width: 100%;
}
.v-container.aa {
  background: #eeefeb;
  max-width: 100%;
}

.v-container.oe {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.oe {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.oe {
    max-width: 1281px;
    /* padding-left: 170px;
    padding-right: 170px;
    padding-top: 50px; */
  }
}

h1 {
  font-size: 54px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
  font-weight: normal;
}

.v-card {
  width: 290px;
  height: 500px;
  margin-left: 15px;
  /* margin-right: 5px; */
}

.v-card:hover {
  box-shadow: 1px 8px 20px #b10801;
  -webkit-transition: box-shadow 0.3s ease-in;
}
</style>