<template>
  <div id="trans">
    <v-container class="tr">
  <v-container class="ts">
    <div class="title text-center">
      <h1 class="pt-5">
        Translator
        <a style="color:#B10801">Schema</a>
      </h1>
      <br />
      <h2>Professional Indonesian to English and English to Indonesian translator</h2>
      <br/>
    </div>
    <v-row class="pa-5">
      <v-col class="ml-auto" cols="12" md="6">
        <h2>Scientific manuscript packet as follows:</h2>
        <v-row>
          <v-col cols="12" ls="10" md="11">
           <h3> <v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> Translation of scientific articles to publish </h3>
           <h3> <v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> Textbook translation</h3>
            <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> Certified</h3>
          </v-col>
        </v-row>
        <br />
        <h2>Scientific manuscript packet as follows:</h2>
        <v-row>
          <v-col cols="12" ls="10" md="11">
            <h3><v-icon style="color: #B10801">mdi-checkbox-marked-circle</v-icon> Technology and engineering manuscript translation</h3>
            <h3><v-icon style="color: #B10801">mdi-checkbox-marked-circle</v-icon> Medical manuscript translation</h3>
            <h3><v-icon style="color: #B10801">mdi-checkbox-marked-circle</v-icon> Legal manuscript translation</h3>
            <h3><v-icon style="color: #B10801">mdi-checkbox-marked-circle</v-icon> Oil and gas manuscript translation</h3>
            <h3><v-icon style="color: #B10801">mdi-checkbox-marked-circle</v-icon> Translation by sworn translation</h3>
            <h3><v-icon style="color: #B10801">mdi-checkbox-marked-circle</v-icon> Printed or image manuscript translation (DTP surcharge)</h3>
            <h3><v-icon style="color: #B10801">mdi-checkbox-marked-circle</v-icon> Translation with very short deadlines (rush order)</h3>
            <h3><v-icon style="color: #B10801">mdi-checkbox-marked-circle</v-icon> And others</h3>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" md="6">
            <v-card class="pa-7 rounded-xl">
              <h2 style="font-weight:bold">Scientific Paper Package All Service</h2>
              <br />
              <div class="text-center">
              <v-btn
                href="https://wa.link/3bn2e2"
                variant="outlined"
                style="color:#B10801"
                rounded
              >Consultation Now !</v-btn></div>
              <br />
              <v-row>
                
                <v-col cols="11" ls="11" md="11">
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> <a> Translate</a> </h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> <a> Layout Template</a></h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> <a> Editing dan Parafrase</a></h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> <a> Proofreading</a></h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> <a> Turnitin / IThenticate</a></h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> <a> Certificate License</a></h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> <a> Service Charge</a> </h3>
                </v-col>
              </v-row>
              <br />
              <br />
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="pa-7 rounded-xl">
              <h2>Scientific Paper Package All Service</h2>
              <br />
              <div class="text-center">
              <v-btn
                href="https://wa.link/3bn2e2"
                variant="outlined"
                style="color:#B10801"
                rounded
              >Consultation Now !</v-btn></div>
              <br />
              <v-col cols="11" ls="11" md="11">
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> Translate </h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> Layout Template </h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> Proofreading </h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> Service Charge </h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> Certificate License </h3>
                  <h3><v-icon style="color: #B10801"> mdi-checkbox-marked-circle </v-icon> Conversion </h3>
                </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</v-container>
</div>
</template>

<script>
export default {};
</script>

<style scoped>
.v-container.tr {
  background: linear-gradient(
    45deg,
    rgba(238, 239, 235, 1) 72%,
    rgba(177, 8, 1, 0.5046393557422969) 100%
  );
  max-width: 100%;
}
.v-container.ts {

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
}

.v-container.ts {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.ts {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.ts {
    max-width: 1281px;
  }
}

h1 {
  font-size: 64px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 16px;
  font-weight: normal;
}

.v-card {
  width: 306px;
  height: 450px;
}

.v-card:hover{ 
     box-shadow: 1px 8px 20px #B10801;
    -webkit-transition:  box-shadow .3s ease-in;
  }

.v-icon {
font-size: 32px;
}
/* h2.a {
  font-size: 100px;
} */
</style>