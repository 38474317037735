<template>
  <div id="ourS">
    <v-container class="ou">
    <v-container class="os">
      <v-row class="pa-5">
        <v-col cols="12" md="7"  class="text-center">
          <v-img
          class="mt-15"
            style="position: relative"
            src="@/assets/ourService.png"
            max-height="625"
            max-width="625"
          ></v-img>
        </v-col>
        <v-col cols="12" md="5">
          <h1>
            Our
            <a style="color:#B10801">Service</a>
          </h1>
          <br />
          <v-row>
          <v-col cols="4" md="2">
            <v-img  src="@/assets/1.png" max-height="70" ></v-img>
          </v-col>
          <v-col cols="8" md="10">
            <h2 class="mt-3">DOAJ Indexing Assistance</h2>
          </v-col>
          <v-col cols="4" md="2">
            <v-img src="@/assets/2.png" max-height="70"></v-img>
          </v-col>
          <v-col cols="8" md="10">
            <h2>Research Program and Study Center</h2>
          </v-col>
          <v-col cols="4" md="2">
            <v-img  src="@/assets/3.png" max-height="70" ></v-img>
          </v-col>
          <v-col cols="8" md="10">
            <h2 class="mt-3">Develop Open Journal System</h2>
          </v-col>
          <v-col cols="4" md="2">
            <v-img  src="@/assets/4.png" max-height="70" ></v-img>
          </v-col>
          <v-col cols="8" md="10">
            <h2 class="mt-3">Data Processing</h2>
          </v-col>
          <v-col cols="4" md="2">
            <v-img  src="@/assets/5.png" max-height="70" ></v-img>
          </v-col>
          <v-col cols="8" md="10">
            <h2 class="mt-3">Turnitin and IThenticate</h2>
          </v-col>
          <v-col cols="4" md="2">
            <v-img  src="@/assets/6.png" max-height="70" ></v-img>
          </v-col>
          <v-col cols="8" md="10">
            <h2 class="mt-3">Parafrase and Copyediting</h2>
          </v-col>
        </v-row>
          <!-- <v-row>
          <v-col cols="1" ls="10" md="10">
            <v-row align="start">
              <v-img  src="@/assets/1.png" max-height="70" ></v-img>
            <h2>DOAJ Indexing Assistance</h2> </v-row>
            <v-row align="start">
              <v-img  src="@/assets/1.png" max-height="70" ></v-img>
            <h2>DOAJ Indexing Assistance</h2> </v-row>

            <v-img src="@/assets/2.png" max-height="70"></v-img>
            <v-img src="@/assets/3.png" max-height="70"></v-img>
            <v-img src="@/assets/4.png" max-height="70"></v-img>
            <v-img src="@/assets/5.png" max-height="70"></v-img>
            <v-img src="@/assets/6.png" max-height="70"></v-img>
          </v-col>
          <v-col cols="2" ls="10" md="10">
          <h2>Research Program and Study Center</h2>
          <h2>Develop Open Journal System</h2>
          <h2>Data Processing</h2>
          <h2>Turnitin and IThenticate</h2>
          <h2>Parafrase and Copyediting</h2>
          </v-col>
          

        </v-row> -->
        </v-col>
      </v-row>
    </v-container>
  </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.v-container.ou {
  background: linear-gradient(
    212deg,
    rgba(238, 239, 235, 1) 72%,
    rgba(177, 8, 1, 0.5046393557422969) 100%
  );
  max-width: 100%;
}

.v-container.os {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.v-container.os {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.os {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.os {
    max-width: 1281px;
  }
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 26px;
}
</style>