<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      class="bg-grey-lighten-4"
      app
      temporary
      theme="light"
      src="logoWeb.png"
    >
      <v-list>
        <v-list-item>
          <v-list-item class="text-center">
            <img src="logoWeb.png" alt="Logo" />
          </v-list-item>
          <v-list-item-content class="text-center">
            <v-list-item-title class="title" style="color:#040A2E">VIKRAMA</v-list-item-title>
            <v-list-item-subtitle style="color:#B10801">Research Institute</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item class="text-center">
          <v-btn class="text-black" text @click="scroll('hero')">Home</v-btn>
          <br />
          <br />
          <v-btn
            class="text-black"
            href="https://drive.google.com/drive/folders/1NIokWuXsVAthriuey085w2NWFlRJ_aw4?hl=id"
          >Discontinued Scopus</v-btn>
          <br />
          <br />
          <v-btn class="text-black" text @click="scroll('ourJ')">Our Journal</v-btn>
          <br />
          <br />
          <v-btn class="text-black" text @click="scroll('ourS')">Service</v-btn>
          <br />
          <br />
          <v-btn class="text-black" text @click="scroll('ourE')">Expertise</v-btn>
          <br />
          <br />
          <v-btn class="text-black" text @click="scroll('editS')">Procedures</v-btn>
          <br />
          <br />
          <v-btn class="text-black" text @click="scroll('trans')">Schema</v-btn>
          <!-- <router-link to="/" custom v-slot="{ navigate }">
            <v-btn class="text-black" @click="navigate" role="link"> Home </v-btn>
          </router-link>-->

          <br />

          <br />
          <br />
          <!-- <router-link to="/about" custom v-slot="{ navigate }">
            <v-btn class="text-black" @click="navigate" role="link"> About Us </v-btn>
          </router-link>-->
          <br />
          <!-- <br />
            <router-link to="/stories" custom v-slot="{ navigate }">
            <v-btn class="text-black" @click="navigate" role="link"> Stories and Visit </v-btn>
          </router-link>
          <br />-->
          <br />
          <!-- <v-btn class="text-black" text @click="scroll('footer')">Contact</v-btn> -->
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">
              {{
              text
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar dark flat class="px 12">
      <v-img class="mx-10" src="@/assets/logoVikrama.png" max-height="125" max-width="125"></v-img>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mr-4 text-black" v-if="isXs" />

      <div v-else>
        <v-btn @click="scroll('hero')">Home</v-btn>
        <!-- <router-link to="/" custom v-slot="{ navigate }">
            <v-btn  @click="navigate" role="link"> Home </v-btn>
        </router-link>-->
        <!-- <v-btn text @click="scroll('#HomeView')" class="text-white">Home</v-btn> -->
        <v-btn
          href="https://drive.google.com/drive/folders/1NIokWuXsVAthriuey085w2NWFlRJ_aw4?hl=id"
          target="_blank"
        >Discontinued Scopus</v-btn>
        <v-btn @click="scroll('ourJ')">Our Journal</v-btn>
        <v-btn @click="scroll('ourS')">Service</v-btn>
        <v-btn @click="scroll('ourE')">Expertise</v-btn>
        <v-btn @click="scroll('editS')">Procedures</v-btn>
        <v-btn @click="scroll('trans')">Schema</v-btn>
      </div>
    </v-app-bar>

    <!-- <v-app-bar
        app
        color=""
        elevation=""
        hide-on-scroll
        prominent
        scroll-target="#scrolling-techniques-4"
        dark
        class="px-15"
        flat
      >
        <v-toolbar-title>
          <v-img src="@/assets/ksiputih.png" max-width="100px" />
        </v-toolbar-title>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="mr-4 text-white"
          v-if="isXs"
        />
        <div v-else>
          <router-link to="/" custom v-slot="{ navigate }">
            <v-btn class="text-white" @click="navigate" role="link"> Home </v-btn>
          </router-link>
          <v-btn text @click="scroll('how')" class="text-white">Service</v-btn>
          <v-btn text @click="scroll('home')" class="text-white">About Us</v-btn>
          <v-btn text @click="scroll('home')" class="text-white">Contact</v-btn>
          
        </div>
    </v-app-bar>-->
  </div>

  <!-- <v-btn class="text-white" text @click="$vuetify.goTo('home')">
            <span class="mr-2">Home</span>
          </v-btn>
          <v-btn class="text-white" text @click="$vuetify.goTo('#features')">
            <span class="mr-2">About</span>
          </v-btn>
          <v-btn class="text-white" text @click="$vuetify.goTo('#download')">
            <span class="mr-2">Service</span>
          </v-btn>
          <v-btn class="text-white" text @click="$vuetify.goTo('#pricing')">
            <span class="mr-2">Contact Us</span>
  </v-btn>-->

  <!-- <div id="navbar">
      <div class="nav-brand">
        <h2>W3hubs</h2>
        <i class="fa fa-navicon" v-on:click="display"></i>
      </div>
  
      <div class="bar-items" :class="{ hide: !show }">
        <div id="menu">
  
  
  
          <RouterLink to="/home">
            Home
          </RouterLink>
  
  
          <RouterLink to="/about">
            About
          </RouterLink>
  
  
          <RouterLink to="/servie">
            Service
          </RouterLink>
  
  
          <RouterLink to="/contact">
            Contact Us
          </RouterLink>
        </div>
      </div>
  
  </div>-->
  <!-- <body>
    <nav class="navbar">
      <div class="logo">MUO</div>
  
  
      <ul class="nav-links">
  
        <input type="checkbox" id="checkbox_toggle" />
  
        <label for="checkbox_toggle" class="hamburger">&#9776;</label>
  
        <div class="menu">
          <li><a href="/">Home</a></li>
  
          <li><a href="/">About</a></li>
  
          <li class="services">
            <a href="/">Services</a>
  
  
            <ul class="dropdown">
              <li><a href="/">Dropdown 1 </a></li>
  
              <li><a href="/">Dropdown 2</a></li>
  
              <li><a href="/">Dropdown 2</a></li>
  
              <li><a href="/">Dropdown 3</a></li>
  
              <li><a href="/">Dropdown 4</a></li>
            </ul>
          </li>
  
          <li><a href="/">Pricing</a></li>
  
          <li><a href="/">Contact</a></li>
        </div>
      </ul>
    </nav>
  </body>-->

  <!-- <v-app-bar color="#1C6758" dark flat class="px 12">
          <v-img
          
      class="mx-10"
      src="../assets/ksiputih.png"
      max-height="100"
      max-width="100"
    ></v-img>
    <v-btn text @click="scroll('#HomeView')" class="text-white">Home</v-btn>
    <v-btn text @click="scroll('how')" class="text-white">Service</v-btn>
    <v-btn text @click="scroll('home')" class="text-white">About Us</v-btn>
    <v-btn text @click="scroll('home')" class="text-white">Contact</v-btn>
    <v-spacer></v-spacer>
    <v-btn text @click="scroll('home')" class="text-white">English</v-btn>
  </v-app-bar>-->
</template>
  
  <script>
export default {
  data: () => ({
    drawer: null,
    isXs: false
  }),
  props: {
    color: String,
    flat: Boolean
  },
  methods: {
    scroll(refName) {
      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    },
    onResize() {
      this.isXs = window.innerWidth < 850;
    }
  },

  watch: {
    isXs(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  }
};
</script>
  
  <style scoped>
.v-app-bar {
  background: linear-gradient(
    347deg,
    rgba(238, 239, 235, 1) 80%,
    rgba(177, 8, 1, 0.44021358543417366) 100%
  );
  padding-left: 50px;
  padding-right: 50px;
}
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}

.span {
  color: #b10801;
}

.v-btn:hover {
  color: #b10801;
}

.v-btn:focus {
  color: #b10801;
}

.v-btn:active {
  color: #b10801;
}
.v-btn {
  text-transform: none !important;
  color: #040a2e;
}
</style>