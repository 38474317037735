<template>
  <div id="hero">
    <v-container class="hh">
  <v-container class="he">
    <v-row class="pt-5">
      <v-col cols="12" md="6" >
        <div class="ma-0 animate__animated animate__backInLeft">
          <h1 class="a pt-16 "> {{ head1 }} </h1>
          <h1 class="a"> <a style="color:#040A2E">Vikrama</a> {{ head2 }} </h1>
          <h1 class="a"> {{ head3 }} </h1>
          <h4> {{ subHead }} </h4>
          <br/>
          <v-btn
            v-bind:href="linkWA"
            variant="outlined"
            rounded
          >
            Contact Us
          </v-btn>
        </div>
      </v-col>
      <v-col class="pa-8" cols="12" md="6">
        <v-img
          class="animate__animated animate__backInRight"
          style="position: relative"
          v-bind:src="imgHero"
          max-height="625"
          max-width="625"
        ></v-img>
      </v-col>
    </v-row>

    <!-- <v-row>
          <v-col cols="6" class="a">
            <div style="position: relative" class="a ">
               
            </div>
            <div style="position: relative" class="a ">
            
            </div>
            <div style="position: relative" class="b">
             
            </div>
            <div style="position: relative" class="b">
              develop solutions and take action
            </div>
            <router-link to="/about" custom v-slot="{ navigate }">
              <v-btn variant="outlined" rounded @click="navigate" role="link">
                <h2>Contact Us</h2>
              </v-btn>
            </router-link>
          </v-col>
          <v-col cols="6">
            <v-img
              style="position: relative"
              src="@/assets/hero_img.png"
              max-height="800"
              max-width="800"
            ></v-img>
          </v-col>
        </v-row> -->
  </v-container>
</v-container>
</div>
</template>

<script>
import imgHero from '@/assets/heroHome.png'

export default {
  data() {
    return {
      head1: 'Welcome to',
      head2: ' Research',
      head3: 'Institute',
      subHead: 'National Journal Publication Solutions (ISSN and SINTA Accredited) and Reputable International Journals',
      linkWA: 'https://wa.link/3bn2e2',
      imgHero ,
    } 
}
};

</script>

<style scoped>
.a {
  font-size: 55px;
  color: #B10801;
}
h4 {
  font-size: 1vw;
  color: rgb(3, 3, 3);
  /* background-image: url("@/assets/logo.svg"); */
}
@media (max-width: 991px){
  .a{
    font-size: 2rem;
  }
  h4 {
  font-size: 1rem;
  color: rgb(14, 13, 13);
  /* background-image: url("@/assets/logo.svg"); */
}
}

.v-container.hh {
  background: linear-gradient(121deg, rgba(238,239,235,1) 72%, rgba(177,8,1,0.5046393557422969) 100%);
  max-width: 100%;
}
.v-container.he {
  background: linear-gradient(214deg, rgba(238,239,235,1) 0%, rgba(177,8,1,0) 0%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
}

.v-container.he {
    padding: 10px;
}

/* Tablet */
@media (min-width:600px) {
  .v-container.he {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width:900px) {
  .v-container.he {
    max-width: 1281px;
  }
}
.v-btn:hover {
  background-color: #B10801;
  color: #ffffff;
}

.v-btn:focus {
  background-color: #d6cda4;
}

.v-btn:active {
  background-color: #d6cda4;
}
.v-btn {
  color: #B10801;
  text-transform: none !important;
}
</style>