<template>
  <div id="indexJ">
    <v-container class="ii">
    <v-container class="ij">
      <div class="title text-center">
        <h1>
          Indexing
          <a style="color:#B10801">Journal</a>
        </h1>
        <br />
      </div>
      <v-row justify="center" >
        <v-img
          src="@/assets/indexing/garuda.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/google.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/doi.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/copernicus.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/road.png"
          max-height="200"
          max-width="200"
        ></v-img>
       
        
      </v-row>
      <v-row justify="center">
        <v-img
          src="@/assets/indexing/isjd.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/wizdom.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/oner.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/ebsco.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/wos.png"
          max-height="200"
          max-width="200"
        ></v-img>
        
      </v-row>
      <v-row justify="center">
        <v-img
          src="@/assets/indexing/econstor.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/ezb.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/HEINONLINE.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/plumx.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/dimentions.png"
          max-height="200"
          max-width="200"
        ></v-img>
      </v-row>
      <v-row justify="center" >
        <v-img
          src="@/assets/indexing/orcid.png"
          max-height="200"
          max-width="200"
        ></v-img>
        <v-img
          src="@/assets/indexing/sienceOpen.png"
          max-height="200"
          max-width="200"
        ></v-img>
       
        
      </v-row>
      <!-- <v-row justify="center">
      <v-col cols="12" md="2" lg="2">
          <v-img
            
            style="position: relative"
            src="@/assets/garuda.png"
            max-height="200"
            max-width="200"
          ></v-img>        
      </v-col>
      <v-col cols="12" md="2" lg="2">
          <v-img
            
            style="position: relative"
            src="@/assets/google.png"
            max-height="200"
            max-width="200"
          ></v-img>        
      </v-col>
      <v-col cols="12" md="2" lg="2" class="pa-5">
          <v-img
            class="mx-auto"
            style="position: relative"
            src="@/assets/ijsccVikrama.png"
            max-height="200"
            max-width="100"
          ></v-img>        
      </v-col>
      <v-col cols="12" md="2" lg="2" class="pa-5">
          <v-img
            class="mx-auto"
            style="position: relative"
            src="@/assets/ijsccVikrama.png"
            max-height="100"
            max-width="100"
          ></v-img>        
      </v-col>
      <v-col cols="12" md="2" lg="2" class="pa-5">
          <v-img
            class="mx-auto"
            style="position: relative"
            src="@/assets/ijsccVikrama.png"
            max-height="100"
            max-width="100"
          ></v-img>        
      </v-col>
      <v-col cols="12" md="2" lg="2" class="pa-5">
          <v-img
            class="mx-auto"
            style="position: relative"
            src="@/assets/ijsccVikrama.png"
            max-height="100"
            max-width="100"
          ></v-img>        
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="2" lg="2">
          <v-img
            class="mx-auto"
            style="position: relative"
            src="@/assets/ijsccVikrama.png"
            max-height="200"
            max-width="200"
          ></v-img>        
      </v-col>
      <v-col cols="12" md="2" lg="2">
          <v-img
            class="mx-auto"
            style="position: relative"
            src="@/assets/ijsccVikrama.png"
            max-height="200"
            max-width="200"
          ></v-img>        
      </v-col>
      <v-col cols="12" md="2" lg="2" class="pa-5">
          <v-img
            class="mx-auto"
            style="position: relative"
            src="@/assets/ijsccVikrama.png"
            max-height="200"
            max-width="100"
          ></v-img>        
      </v-col>
      <v-col cols="12" md="2" lg="2" class="pa-5">
          <v-img
            class="mx-auto"
            style="position: relative"
            src="@/assets/ijsccVikrama.png"
            max-height="100"
            max-width="100"
          ></v-img>        
      </v-col>
      <v-col cols="12" md="2" lg="2" class="pa-5">
          <v-img
            class="mx-auto"
            style="position: relative"
            src="@/assets/ijsccVikrama.png"
            max-height="100"
            max-width="100"
          ></v-img>        
      </v-col>
      </v-row>-->
    </v-container>
  </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.v-container.ii {
  background: linear-gradient(
    337deg,
    rgba(238, 239, 235, 1) 72%,
    rgba(177, 8, 1, 0.5046393557422969) 100%
  );
  max-width: 100%;
}
.v-container.ij {
  max-width: 100%;
}

.v-container.ij {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.ij {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.ij {
    max-width: 1281px;
  }
}

h1 {
  font-size: 60px;
}

.v-img {
  margin-left: 5px;
  margin-top: 10px;
  margin-right: 5px;
}
</style>