<template>
  <v-app>
  <HeroHome/>
  <OurJournal/>
  <OurService/>
  <IndexingJournal/>
  <OurExpertise/>
  <EditingServiceProcedure/>
  <TranslatorSchema/>
  <FooterVikrama/>
</v-app>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import HeroHome from '@/components/HeroHome.vue';
import OurJournal from '@/components/OurJournal.vue';
import OurService from '@/components/OurService.vue';
import IndexingJournal from '@/components/IndexingJournal.vue';
import OurExpertise from '@/components/OurExpertise.vue';
import EditingServiceProcedure from '@/components/EditingServiceProcedure.vue';
import TranslatorSchema from '@/components/TranslatorSchema.vue';
import FooterVikrama from '@/components/FooterVikrama.vue'

export default defineComponent({
  name: 'HomeView',

  components: {
    HeroHome, OurJournal, OurService, IndexingJournal, OurExpertise, EditingServiceProcedure, TranslatorSchema, FooterVikrama
  },
});
</script>
