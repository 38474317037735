<template>
  <div id="editS">
    <v-container class="es">
  <v-container class="esp">
    <div class="title text-center">
      <h1 class="pt-5">Editing <a style="color:#B10801">Service</a>  Procedures</h1>
      <br />
    </div>
    <v-row class="pt-10">
      <v-col cols="12" md="6" lg="6">
        <v-row justify="center">
          <v-col cols="12" md="6" lg="6">
            <div class="pr-5">
            <v-card class="pa-5 text-center rounded-xl">
              <v-img
                class="mx-auto"
                style="position: relative"
                src="@/assets/articelSubmit.png"
                max-height="100"
                max-width="100"
              ></v-img>
              <br />
              <h2>Articles submission</h2>
              <br />
              <h4>Submit your manuscripts and documentsto email or WhatsApp number.</h4>
              <br />
              <br />
            </v-card>
          </div>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <v-card class="pa-5 text-center rounded-xl">
              <v-img
                class="mx-auto"
                style="position: relative"
                src="@/assets/analyzing.png"
                max-height="100"
                max-width="100"
              ></v-img>
              <br />
              <h2>Analyzing</h2>
              <br />
              <h4>Your manuscripts and documents will be analyzed by independent team</h4>
              <br />
              <br />
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" lg="6">
            <v-card class="pa-5 text-center rounded-xl">
              <v-img
                class="mx-auto"
                style="position: relative"
                src="@/assets/payment.png"
                max-height="100"
                max-width="100"
              ></v-img>
              <br />
              <h2>Payment</h2>
              <br />
              <h4>Manuscripts and documents are sent by paying 50% DP. Full payment can be paid after the work is completed.</h4>
              <br />
              <br />
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <v-card class="pa-5 text-center rounded-xl">
              <v-img
                class="mx-auto"
                style="position: relative"
                src="@/assets/processing.png"
                max-height="100"
                max-width="100"
              ></v-img>
              <br />
              <h2>Processing</h2>
              <br/>
              <h4>Manuscripts and documents are processed in some stages; editing, translation process, proofreading, templates and will accept certificate</h4>
      
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-img
          style="position: relative"
          src="@/assets/editingService.png"
          max-height="800"
          max-width="800"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</v-container>
</div>
</template>

<script>
export default {};
</script>

<style scoped>
.v-container.es {
  background: linear-gradient(121deg, rgba(238,239,235,1) 72%, rgba(177,8,1,0.5046393557422969) 100%);
  max-width: 100%;
}
.v-container.esp{
  
      background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
}

.v-container.esp {
  padding: 10px;
}

/* Tablet */
@media (min-width: 600px) {
  .v-container.esp {
    max-width: 1281px;
  }
}

/* Desktop */
@media (min-width: 900px) {
  .v-container.esp {
    max-width: 1281px;
  }
}

.v-card {
  width: 269px;
  height: 340px;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
  font-weight: normal;
}

.v-card:hover{ 
     box-shadow: 1px 8px 20px #B10801;
    -webkit-transition:  box-shadow .3s ease-in;
  }

</style>